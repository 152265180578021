import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { Checkbox } from "@mui/material";
import md5 from 'md5';
import _ from 'lodash';

function MultiChoiceItem(props) {
    const { t, i18n } = useTranslation();

    const optionsLen = useState(props.data.options.length);
    const [selectedOptions, setSelectedOptions] = useState(Array(optionsLen));
    const [selected, setSelected] = useState(false);

    const _id = props.data.id ? props.data.id : md5(props.data.img + props.data.name);

    useEffect(() => {
        if (props.basket && props.basket[_id]) {
            const isSame = _.isEqual(props.basket[_id].value.selectedOption, selectedOptions);
            if (!isSame) {
                setSelectedOptions(props.basket[_id].value.selectedOption);
            }
            if (selected !== props.basket[_id].selected) {
                setSelected(props.basket[_id].selected);
            }
        } else {
            setSelectedOptions(Array(optionsLen));
            setSelected(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.basket]);

    const getSum = (selectedOptions) => {
        let key = "";
        selectedOptions.forEach((opt, index) => {
            key += opt;
            if (index < selectedOptions.length - 1) key += ","
        });
        let selectedItem = props.data.values.find((val) => val.text === key);
        return selectedItem?.value || 0;
    }

    const handleSelectItem = (value, index) => {
        let selectedTmp = [...selectedOptions];
        selectedTmp[index] = value;
        setSelectedOptions(selectedTmp);
        let tax = props.data.tax || 23;

        if (props.updateBasket && getSum(selectedTmp)) {
            setSelected(true);
            props.updateBasket(_id, {
                value: getSum(selectedTmp),
                name: props.data.name,
                selectedOption: selectedTmp,
                valueWithTax: getSum(selectedTmp)*(1+tax/100),
                tax: tax
           //     selectedOptionPrompt:  Array.form(props.data.options, (op) => op.prompt)
            }, true);
        }
    }

    const handleChange = (event) => {
        setSelected(event.target.checked);
        let tax = props.data.tax || 23;
        props.updateBasket(_id, {
            value: getSum(selectedOptions),
            name: props.data.name,
            selectedOption: selectedOptions,
            valueWithTax: getSum(selectedOptions)*(1+tax/100),
            tax: tax
           // selectedOptionPrompt:  Array.form(props.data.options, (op) => op.prompt)
        }, event.target.checked);
    };

    return (
        <div className='item'>
            <div>
                <div className={clsx('item-header-image', props.data.imgClass)}>
                    <img src={props.data.img} alt="no" />
                </div>
                <div className='item-title'>{props.data.name}</div>
            </div>

            <div className='item-description'>{props.data.description}</div>

            <div>
                {props.data.options.map((opt, index) => {
                    return <div key={"o" + index} className={clsx('item-option', index === props.data.options.length && "with-one")}>
                        <div className='option-question'>{opt.prompt}</div>
                        <div className='option-input option-values-3'>
                            {opt.values.map((val, innerIndex) => {
                                return <div key={"oi" + innerIndex} onClick={() => { handleSelectItem(val, index) }} className={clsx('option-value', selectedOptions[index] === val && "selected")}>
                                    {val} </div>
                            })}
                        </div>
                    </div>
                })}

                <div className='item-value'>
                    <div className='value-selected-item'>
                        <Checkbox style={{ padding: 0, color: "#D9E8F9"}} checked={selected} disabled={getSum(selectedOptions) === 0} onChange={handleChange} />
                    </div>
                    <div className={clsx('value-sum', !selected && 'not-selected')}>
                        {getSum(selectedOptions)} zł
                    </div>
                </div>
            </div>

        </div>
    );
}

export default MultiChoiceItem;
