import { Fragment, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Checkbox, Input } from "@mui/material";
import md5 from 'md5';
import _ from 'lodash';


function NumericChoiceItem(props) {
    const { t, i18n } = useTranslation();

    const [selectedOptions, setSelectedOptions] = useState(Array(props.data.options.length));

    const _id = props.data.id ? props.data.id : md5(props.data.img + props.data.name);

    useEffect(() => {
        if (props.basket && props.basket[_id]) {
            const isSame = _.isEqual(props.basket[_id].value.selectedOption, selectedOptions);
            if (!isSame) {
                setSelectedOptions(props.basket[_id].value.selectedOption);
            }
            if (selected !== props.basket[_id].selected) {
                setSelected(props.basket[_id].selected);
            }
        } else {
            setSelectedOptions(Array(props.data.options.length));
            setSelected(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.basket]);

    const [selected, setSelected] = useState(false);

    const handleChange = (event) => {
        setSelected(event.target.checked);
        let tax = props.data.tax || 23;
        let prompts = Array.from(props.data.options, (op) => op.prompt);

        props.updateBasket(_id, {
            valueWithTax: getSum(selectedOptions)*(1+tax/100),
            tax: tax,
            selectedOptionPrompt:  prompts,
            value: getSum(selectedOptions),
            name: props.data.name,
            selectedOption: selectedOptions
        }, event.target.checked);

    };

    const handleInputChange = (event, index) => {
        const { value } = event.target;
        let v = value;

        if(v < 0) v = 0;

        let selectedTmp = [...selectedOptions];
        selectedTmp[index] = v;
        setSelectedOptions(selectedTmp);

        let prompts = Array.from(props.data.options, (op) => op.prompt);

        let isValid = true;

        if(props.data.minValue) {
            let count = 0;
            selectedTmp.forEach((opt, index) => {
                if (opt) count += Number(opt);
            });

            if(count < props.data.minValue) isValid = false;
        }
        let tax = props.data.tax || 23;

        if (props.updateBasket && getSum(selectedTmp) && (!props.data.minValue || isValid)) {
            setSelected(true);
            props.updateBasket(_id, {
                value: getSum(selectedTmp),
                valueWithTax: getSum(selectedTmp)*(1+tax/100),
                tax: tax,
                name: props.data.name,
                selectedOption: selectedTmp,
                selectedOptionPrompt: prompts
            }, true);
        } else {
            setSelected(false);
            props.updateBasket(_id, {
                value: getSum(selectedTmp),
                valueWithTax: getSum(selectedTmp)*(1+tax/100),
                tax: tax,
                name: props.data.name,
                selectedOption: selectedTmp,
                selectedOptionPrompt:  prompts
            }, false);
        }
    }

    const getSum = (selectedOptions) => {
        let sum = 0;

        selectedOptions.forEach((opt, index) => {
            if (opt) sum += (opt * props.data.options[index]?.value);
        });
        return sum || 0
    }

    const getValuesSum = (selectedOptions) => {
        let sum = 0;

        selectedOptions.forEach((opt, index) => {
            if (opt) sum += Number(opt);
        });
        return sum || 0
    }

    const disabledCheck = (selectedOptions) => {
        return getSum(selectedOptions) === 0 || (props.data.minValue && getValuesSum(selectedOptions) < props.data.minValue)
    }


    return (
        <div className='item not-selected' key={props.activeStep + props.data.name}>
           <div>
               <div className='item-header-image'>
                   <img src={props.data.img} alt="./" />
               </div>
               <div className='item-title'>{props.data.name}</div>
           </div>

            <div className='item-description'>{props.data.description}</div>

            <div style={{ display: "grid"}}>
                {props.data.options.map((opt, index) => {
                    return (<div key={'ns' + index} className='item-numeric' >
                        <div className='option-question'>{opt.prompt}</div>
                        <div className='option-input' style={{ fontSize: 10 }}>
                            <Input value={selectedOptions[index] || ""}
                                   inputProps={{
                                       type: 'number',
                                       min: 0,
                                   }}
                                   type="number" onChange={(e) => handleInputChange(e, index)} /></div>
                    </div>);
                })}

                <div style={{minHeight: 20, paddingTop: 5}}>
                    <div className={'error-prompt'}>
                        {props.data.minValue && getSum(selectedOptions) !== 0 && getValuesSum(selectedOptions) < props.data.minValue && !selected && <>Minimalna liczba uczestników: {props.data.minValue}</> }
                    </div>

                    <div className={'extra-prompt'}>
                        {getSum(selectedOptions) !== 0 && props.data.description_prompt && <> {props.data.description_prompt}</> }
                    </div>
                </div>


                <div className='item-value'>
                    <div className='value-selected-item'>
                        <Checkbox style={{ padding: 0, color: "#D9E8F9" }} checked={selected} disabled={disabledCheck(selectedOptions)} onChange={handleChange} />
                    </div>
                    <div className={clsx('value-sum', !selected && 'not-selected')}>
                        {getSum(selectedOptions)} zł
                    </div>
                </div>
            </div>

        </div>
    );
}

export default NumericChoiceItem;
