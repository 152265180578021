// import logo from './logo.svg';
import './App.scss';

import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {
  useLocation
} from "react-router-dom";

import MultiChoiceItem from './items/multi_choice_item';
import NumericChoiceItem from './items/numeric_choice_item';

import data from "./data.json";
import Grid from "@mui/material/Grid";
import {
  Accordion, AccordionDetails, AccordionSummary,
  Alert, Autocomplete,
  Divider,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Button,
  Select, Snackbar, TextField, Typography, Modal
} from "@mui/material";
import MixedChoiceItem from "./items/mixed_choice_item";

import clsx from 'clsx';
import md5 from 'md5';
import _ from 'lodash';
import axios from 'axios';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const resources = {
  en: {
    translation: {
      "hello1": "Hello world",
      "current_temperature": "Current temperature",
      "feels_like": "Feels like",
      "humidity": "Humidity"
    },
  },
  pl: {
    translation: {
      "hello1": "Witaj świecie",
      "current_temperature": "Current temperature",
      "feels_like": "Feels like",
      "humidity": "Humidity"
    },
  }
};

i18n.use(initReactI18next).init({
  resources,
  // lng: "en",
  // fallbackLng: "en",
  lng: "pl",
  fallbackLng: "pl",
  interpolation: {
    escapeValue: false,
  },
});

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function App() {
  const { t, i18n } = useTranslation();
  const [basket, setBasket] = useState({});

  const initCompanyData = {
    name: "",
    email: "",
    phone: "",
    contactPerson: ""
  }

  const initEventData = {
    startDate: null,
    startTime: null,
    duration: "",
    comment: "",
    location: ""
  };

  const [companyData, setCompanyData] = useState(initCompanyData);
  const [eventDetails, setEventDetails] = useState(initEventData);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    let savedBasket = localStorage.getItem("basket");
    // console.log("savedBasket", savedBasket);
    // TODO: walidacja daty i wersji - jeżeli cokolwiek sie nie zgadza wywalamy stary przechowany obiekt!


    let basketVersion = localStorage.getItem("basket_version");

    if(Number(basketVersion) === data.version) {
      if (savedBasket) {
        let b = JSON.parse(savedBasket);
        setBasket(b);
      }

      let savedCompanyData = localStorage.getItem("company_data");
      if (savedCompanyData) {
        let b = JSON.parse(savedCompanyData);
        setCompanyData(b);
      }

      let eventData = localStorage.getItem("event_data");
      if (eventData) {
        let b = JSON.parse(eventData);
        b.startDate = dayjs(b.startDate, "DD/MM/YYYY");
        setEventDetails(b);
      }
    } else {
      clearBasket()
    }


  }, []);

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = await axios.post('http://127.0.0.1:3001/', { answer: 42 });
  //     console.log("res", res.data, res);
  //   }
  //   fetchData();
  // }, []);

  const clearBasket = () => {
    setBasket({});
    setCompanyData(initCompanyData);
    setEventDetails(initEventData);

    localStorage.removeItem("basket");
    localStorage.removeItem("basket_version");
    localStorage.removeItem("basket_when");
    localStorage.removeItem("company_data");
    localStorage.removeItem("event_data");
  }


  const clearBasketItems = () => {
    setBasket({});

    localStorage.removeItem("basket");
    localStorage.removeItem("basket_version");
    localStorage.removeItem("basket_when");

  }


  const getBasketItems = () => {
    const basketSummary = [];

    const basketItems = [];

    _.forEach(data.start.prices, (element, i) => {
      let _id = element.id;

      if(basket && basket[_id] && basket[_id].selected === true) {
        basketItems.push({
          name: basket[_id].value.name,
          value: basket[_id].value.value,
          valueWithTax: basket[_id].value.valueWithTax?.toFixed(2),
          options: [basket[_id].value.selectedOption]
        });
      }
    });
    if (basketItems.length > 0) {
      basketSummary.push({
        name: "Ilość uczestników",
        items: basketItems
      });
    }

    _.forEach(data.list, (element, i) => {
      // console.log(i, element);
      const basketItems = [];
      _.forEach(element.items, item => {
        const _id = item.id ? item.id : md5(item.img + item.name);
        // console.log("     ", item);
        if (basket && basket[_id] && basket[_id].selected === true) {
          // console.log("     ", basket[_id]);
          basketItems.push({
            name: basket[_id].value.name,
            value: basket[_id].value.value,
            valueWithTax: basket[_id].value.valueWithTax?.toFixed(2),
            options: basket[_id].value.selectedOption,
            optionsPrompt: basket[_id].value.selectedOptionPrompt
          });
        }
      });

      if (basketItems.length > 0) {
        basketSummary.push({
          name: element.title,
          items: basketItems
        });
      }
    });

    return basketSummary;
  }

  const sendTestData = () => {

    if(formValid()) {
      setLoading(true);
      const basketSummary = getBasketItems();
      // console.log(basketSummary);

      async function fetchData() {
        // FIXME: this need to be available from same context path
        // FIXME: nginx to be configured ...
        // const response = await axios.post('http://127.0.0.1:3001/basketPDF', {
        const response = await axios.post('/api/basketPDF', {
          // data: data,
          //  basket: basket,
          basketSummary: basketSummary,
          companyData: companyData,
          sum:{
            sum: sum(),
            sumWithTax: sum(true),
            sumCat8: sumByTaxCategory(8),
            sumCat8Tax: sumByTaxCategory(8, true),
            sumCat23: sumByTaxCategory(23),
            sumCat23Tax: sumByTaxCategory(23, true),
          },
          eventDetails: {
            ...eventDetails,
            startDate: eventDetails.startDate?.format("DD/MM/YYYY") || ""
          }
        }, { responseType: 'blob' });
        // console.log("res", response.data, response);

        // FIXME: ???

       // console.log(response.data.emailStatus)

        function downloadBlob(blob, name = 'file.txt') {
          const blobUrl = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = name;
          document.body.appendChild(link);
          link.dispatchEvent(
              new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
              })
          );
          document.body.removeChild(link);
        }

        // const blob = new Blob([new Uint8Array(response.data.pdf.data).buffer], {type: 'application/pdf'});
        // const fileURL = window.URL.createObjectURL(blob);
        // let tab = window.open();
        // tab.location.href = fileURL;

        setLoading(false);

        var fileURL = window.URL.createObjectURL(response.data);
        let tab = window.open();
        if(tab) tab.location.href = fileURL;
      }

      try {
        fetchData();
      } catch (e) {
        console.log(e);
        setLoading(false);
      }

    } else {
      setOpen(true);
    }
  }

  // let query = useQuery();
  // console.log("query", query);
  // TODO: DEMO - można później przemyśleć jak to wspierać
  // http://localhost:3000/?lang=pl
  // useEffect(() => {
  //   if (query.get("lang")) {
  //     i18n.changeLanguage(query.get("lang"));
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // const changeToPl = () => {
  //   console.log("i18next.language", i18n.language);
  //   if (i18n.language === "pl") {
  //     changeToEn();
  //   } else {
  //     i18n.changeLanguage("pl");
  //   }
  // }
  // const changeToEn = () => {
  //   i18n.changeLanguage("en");
  // }

  // const [groups, setGroups] = useState(Array(data.list.length));
  // useEffect(() => {
  //   setGroups(() => {
  //     let g = [];
  //     data.list.forEach((group) => {
  //       g.push(Array(group.items.length))
  //     })
  //     return g;
  //   })
  // }, []);

  const updateBasket = (id, value, selected) => {
    // console.log("id, value, selected", id, value, selected);
    const newBasket = _.cloneDeep(basket);
    newBasket[id] = {
      value: value,
      selected: selected
    };
    setBasket(newBasket);

   // console.log("newBasket", newBasket);

    localStorage.setItem("basket", JSON.stringify(newBasket));
    localStorage.setItem("basket_version", data.version);
    localStorage.setItem("basket_when", "TODO: date!");
  }



  const getColumnSmSize = (itemsLength) => {
    if(itemsLength === 1) return 10;
    if(itemsLength === 4)  return 6;
    let columnSize = 12 / itemsLength;
    return Math.min((columnSize < 5 ? 4 : columnSize), 6);
  }

  const getColumnSize = (itemsLength) => {
    if(itemsLength === 1) return 6;
    let columnSize = 12 / itemsLength;
    return Math.min((columnSize < 2 ? 3 : columnSize), 3);
  }

  const sum = (withTax = false) => {
    let s = 0;
    _.forEach(basket, function (value, key) {
      if (value.selected) {
        s += value.value[withTax? 'valueWithTax': 'value'];
      }
    });
    return formatAmount(Number(s)?.toFixed(2));
  }

  const sumByTaxCategory = (tax, withTax = false) => {
    let s = 0;
    _.forEach(basket, function (value, key) {
      if (value.selected && value.value?.tax === tax) {
        s += value.value[withTax? 'valueWithTax': 'value'];
      }
    });
    return formatAmount(s?.toFixed(2));
  }

  const formatAmount = (amount) =>{
    // Check if the amount is a number
    if (isNaN(amount)) {
      return "Invalid";
    }

    // Convert the amount to a string and split into integer and decimal parts
    const [integerPart, decimalPart] = amount.toString().split(".");

    // Add a space every 3 digits in the integer part
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    // Combine the formatted integer part and the decimal part
    const formattedAmount = decimalPart
        ? formattedIntegerPart + "," + decimalPart
        : formattedIntegerPart;

    return formattedAmount;
  }


  const getSummary = () => {
    return <div className={'app-step'} >
      <div className={'sub-title'}>Dane kontaktowe </div>
      <div className={'form-content'}>
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={12}>
            {t("Nazwa firmy:")}
            <Input fullWidth
                   defaultValue={companyData.name}
                   name={"name"}
                   onChange={handleCompanyInputChange}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            {t("Osoba kontaktowa:")}
            <Input fullWidth
                   defaultValue={companyData.contactPerson}
                   name={"contactPerson"}
                   onChange={handleCompanyInputChange}/>
          </Grid>
          <Grid item xs={12} md={4}>
            {t("Telefon")}
            <Input fullWidth
                   value={companyData.phone}
                   name={"phone"}
                   inputProps={{ maxLength: 9 }}
                   onChange={handleCompanyPhoneChange}/>

          </Grid>
          <Grid item xs={12} md={4}>
            {t("E-mail")}
            <Input fullWidth
                   defaultValue={companyData.email}
                   name={"email"}
                   onChange={handleCompanyInputChange}/>
          </Grid>

        </Grid>
      </div>
      <div className={clsx("send-button")}  onClick={sendTestData}>
          Wyślij ofertę na maila
      </div>

      <div style={{marginTop: 30}}>
        <Accordion>
          <AccordionSummary
              expandIcon={<div style={{rotate: "90deg", fontSize: 40}}> &#8250; </div>}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
            <Typography component={'span'}> <div style={{color: '#0075ac', fontSize: 20}}>Podsumowanie </div> </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className={'form-content'}>
              {getBasketItems().map((item)=>{
                return <Grid container spacing={1} alignItems={"center"}>
                  <Grid xs={12} item>
                    <div className={'summary-step-title'}> {item.name} </div>
                  </Grid>
                  {item.items.map((item)=>{
                    return  <Grid xs={12} item>
                      <Grid container spacing={1} alignItems={"center"}>
                        <Grid xs={12} md={5} item>
                          <div className={'summary-item-title'}> {item.name} </div>
                        </Grid>
                        <Grid xs={12} md={5} item>
                          {Array.isArray(item.options) && item.options.map((op, index) => {
                            return <Grid container spacing={2} style={{paddingLeft: 10}} alignItems={"center"}>
                              {item.optionsPrompt?.[index] && <Grid xs={6} item>{item.optionsPrompt?.[index]} </Grid>}
                              <Grid xs={item.optionsPrompt?.[index]? 6 : 12} item>{op || 0}</Grid>
                            </Grid>
                          })}
                        </Grid>
                        <Grid xs={12} md={2} item>
                          <div style={{textAlign: "right", paddingRight: 5}}> {formatAmount(item.value)} zł </div>
                        </Grid>
                        <Grid xs={12} item>
                          <Divider/>
                        </Grid>
                      </Grid>

                    </Grid>

                  })}
                </Grid>
              })}



              <Grid container spacing={0} alignItems={"center"} style={{marginTop: 25, background: "#D9E8F9"}}>
                <Grid xs={12} item> <div className={'summary-title'}>Podsumowanie wydarzenia </div>  </Grid>
              </Grid>
              <Grid container spacing={0} alignItems={"center"} style={{marginTop: 0, background: "#D9E8F9"}}>
                <Grid xs={12} md={4} item> </Grid>
                <Grid xs={4} md={3} item><div className={'summary-sub-title value'}> Kwota netto </div></Grid>
                <Grid xs={4} md={2} item> <div className={'summary-sub-title value'}> VAT</div> </Grid>
                <Grid xs={4} md={3} item><div className={'summary-sub-title value'}> Kwota brutto</div></Grid>
              </Grid>
              <Grid container spacing={0} alignItems={"center"}>
                <Grid xs={12} md={4} item><div className={'summary-value'}>Organizacja wydarzenia </div></Grid>
                <Grid xs={4} md={3} item><div className={'summary-value value'}> {sumByTaxCategory(23)} zł </div> </Grid>
                <Grid xs={4} md={2} item> <div className={'summary-value value'}>23% </div> </Grid>
                <Grid xs={4} md={3} item><div className={'summary-value value'}>{sumByTaxCategory(23, true)} zł</div></Grid>

                <Grid xs={12} md={4} item><div className={'summary-value'}> Usługa gastronomiczna </div></Grid>
                <Grid xs={4} md={3} item><div className={'summary-value value'}>{sumByTaxCategory(8)} zł</div></Grid>
                <Grid xs={4} md={2} item> <div className={'summary-value value'}>8% </div></Grid>
                <Grid xs={4} md={3} item><div className={'summary-value value'}>{sumByTaxCategory(8, true)} zł</div></Grid>

              </Grid>
              <Grid container spacing={0} alignItems={"center"} style={{background: "#D9E8F9"}}>
                <Grid xs={12} md={4} item><div className={'summary-sub-title'}>Suma</div></Grid>
                <Grid xs={6} md={3} item><div className={'summary-sub-title value'}>{sum()} zł</div></Grid>
                <Grid xs={0} md={2} item/>
                <Grid xs={6} md={3} item><div className={'summary-sub-title value'}> {sum(true)} zł</div></Grid>
              </Grid>
              <Grid xs={12} item><div className={'summary-bottom-prompt'}> Niniejsza oferta nie stanowi oferty handlowej w rozumieniu art. 66 kodeksu cywilnego </div> </Grid>
              <Grid xs={12} item> <div className='clear-btn' onClick={clearBasketItems}>wyczyść koszyk</div> </Grid>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>

    // TODO: nowe summary !
  };

  const goBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      document.getElementById("content").scrollTop = 0;
    }
  }
  const goNext = () => {
    if (activeStep !== data.list.length + 2) {
      setActiveStep(activeStep + 1);
      document.getElementById("content").scrollTop = 0;
    }
  }

  const handleCompanyPhoneChange = (e) =>{
    const {value, name} = e.target;

    let newValue = value;
    newValue = newValue.replace(/\D/g, '');

    let companyDataNew = {
      ...companyData,
      [name]: newValue
    };

    setCompanyData(companyDataNew);
    localStorage.setItem("company_data", JSON.stringify(companyDataNew));
  }

  const handleCompanyInputChange = (e) => {
    const {value, name} = e.target;

    let companyDataNew = {
      ...companyData,
      [name]: value
    };

    setCompanyData(companyDataNew);
    localStorage.setItem("company_data", JSON.stringify(companyDataNew));
  }

  const handleDateChange = (e) => {

    let eventDataNew = {
      ...eventDetails,
      startDate: e
    };

    setEventDetails(eventDataNew);

    let newData = {
      ...eventDataNew,
      startDate: e.format("DD/MM/YYYY")
    }

    localStorage.setItem("event_data", JSON.stringify(newData));

  }

  const handleEventInputChange = (e) => {
    const {value, name} = e.target;

    let eventDataNew = {
      ...eventDetails,
      [name]: value
    };

    setEventDetails(eventDataNew);
    localStorage.setItem("event_data", JSON.stringify(eventDataNew));
  }

  const handleEventTimeChange = (e, value) => {

    let eventDataNew = {
      ...eventDetails,
      startTime: value
    };

    setEventDetails(eventDataNew);
    localStorage.setItem("event_data", JSON.stringify(eventDataNew));
  }

  const handleCountChange = (e, p) => {
    let tax = (p.tax || 23)/100;
    let value = e.target.value;
    if(value < 0) value = 0;

    let selected = true;

    if(p.minValue && value < p.minValue)  selected = false;

    updateBasket(p.id, {
      name: p.prompt,
      selectedOption: value,
      value: value*p.value,
      valueWithTax: value*p.value*(1+tax/100),
      tax: p.tax
    }, selected)
  }

  const renderScreen = () => {
    return <div className={'welcome_container'}>
      <div className={'welcome_text'} dangerouslySetInnerHTML={{__html: data.start.welcomeText}}/>
      <img src={"./konfiguruj.svg"} style={{cursor: "pointer"}} onClick={()=>{setActiveStep(1)}} alt="./" />
    </div>
  }

  const generateTimeOptions = () => {
    const options = [];
    for (let hour = 9; hour < 18; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        options.push(`${formattedHour}:${formattedMinute}`);
      }
    }
    options.push(`18:00`);
    return options;
  };


  const renderStartScreen = () => {
    return <div className={'app-step'} >

      <div className={'sub-title'}>Preferowana lokalizacja </div>

      <div className={'form-content'}>
        <Grid container spacing={3} alignItems={"center"}>
          <Grid item xs={12} md={4}>
            <FormControl fullWidth className={"root"}>
              {/*<div className={'init-step-label'}> {t("Preferowana lokalizacja")} </div>*/}
              <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={eventDetails.location}
                  name={"location"}
                  variant={"filled"}
                  size={"small"}
                  renderValue={(selected) => {
                    return selected.name
                  }}
                  onChange={handleEventInputChange}
              >
                {data.start.locations.map((location) => {
                  return <MenuItem key = {location.name} value={location}>{location.name}</MenuItem>
                })}

              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={4}>
            {eventDetails.location?.address}
          </Grid>
          <Grid item xs={12} md={4}>
            {eventDetails.location?.parking}
          </Grid>
        </Grid>
      </div>
      <div className={'sub-title'}> Preferowany termin imprezy  </div>
      <div className={'form-content'}>
        <Grid container spacing={3} alignItems={"center"}>

        <Grid item xs={12} md={4}>
          <div className={'init-step-label'}> {t("Data imprezy:")} </div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={eventDetails.startDate}
                        onChange={handleDateChange}
                        format={"DD/MM/YYYY"}
                        disablePast
                        slotProps={{ textField: { error: false, variant: "standard", fullWidth: true }}}
                        className={'datePicker'}/>
          </LocalizationProvider>

        </Grid>
        <Grid item xs={12} md={4}>
          <div className={'init-step-label'}> {t("Godzina wydarzenia:")} </div>
          <FormControl fullWidth className={"root2"}>
            <Autocomplete
                disablePortal
                defaultValue={eventDetails.startTime}
                id="combo-box-demo-2"
                disableClearable
                onChange={handleEventTimeChange}
                options={generateTimeOptions()}
                renderInput={(params) => <TextField variant={"filled"} {...params} />}
            />
          </FormControl>

        </Grid>

        <Grid item xs={12} md={4}>
          <div className={'init-step-label'}> {t("Planowany czas trwania:")} </div>
          <FormControl fullWidth className={"root"}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={eventDetails.duration}
                variant={"filled"}
                size={"small"}
                name="duration"
                onChange={handleEventInputChange}
            >
              {data.start.durations.map((dur) => {
                return <MenuItem key = {dur} value={dur}>{dur}</MenuItem>
              })}

            </Select>
          </FormControl>

        </Grid>
        </Grid>
      </div>

      <div className={'sub-title'}>  {t("Liczba uczestników")} </div>
      <div className={'form-content'}>
        <Grid container spacing={3} alignItems={"center"}>

        {data.start.prices.map((p) => {
          return <Grid xs={6} item key={p.prompt}>
            <Grid container alignItems={"center"}>
              <Grid item xs={12} md={4}>{p.prompt} </Grid>
              <Grid item xs={12} md={4}>
                <Input fullWidth
                       value={basket[p.id]?.value?.selectedOption || ""}
                       name={"email"}
                       type="number"
                       inputProps={{
                         type: 'number',
                         min:  0,
                       }}
                       className={"option-input"}
                       onChange={(e)=>{handleCountChange(e, p)}}/>
              </Grid>
              <Grid xs={12} item>
                <div className={'error-prompt'} style={{marginTop: 10, height: 15}}>
                  {p.minValue && basket[p.id]?.value?.selectedOption < p.minValue &&  <div> Minimalna liczba dzieci: {p.minValue} </div>}
                </div>
              </Grid>
            </Grid>
          </Grid>
        })}
      </Grid>
      </div>
    </div>
  };

  const formValid = () => {
    const regPhone = /^[0-9\+]{9}$/;
    const regEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    return companyData.phone.match(regPhone) && companyData.name.length > 3 && companyData.email.match(regEmail) && companyData.contactPerson.length > 3
  }


  const [open, setOpen] = useState(false);
  const [openEmailErrorModal, setOpenEmailErrorModal] = useState(false);
  const [openEmailSuccessSnack, setEmailSuccessSnack] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <div className="App">
      <Modal
          open={openEmailErrorModal}
          onClose={() => setOpenEmailErrorModal(false)}
      >
        <div>
          <h2>Błąd wysyłania </h2>
          <p>Błąd wysyłania wiadomości email</p>
          <Button onClick={sendTestData}>Spróbuj ponownie</Button>
        </div>

      </Modal>

      {loading && <>
        <div className="loader-container">
          <div className="spinner"/>
        </div>
        <div className="loader-background"> </div>
      </>
      }

      <Snackbar
          open={open}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          autoHideDuration={2000}
          onClose={()=>{setOpen(false)}}
      >
        <Alert severity="error"> {t("Proszę uzupełnić dane kontaktowe firmy")} </Alert>
      </Snackbar>

      <Snackbar
          open={openEmailSuccessSnack}
          anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
          onClose={()=>{setEmailSuccessSnack(false)}}
      >
        <Alert severity="success"> {t("Email został wysłany")} </Alert>
      </Snackbar>

      <div className="header">
        <div className={clsx('step-back', activeStep === 0 && 'disabled')} onClick={goBack}>
          <span className={'arrow-icon'}> &#60; </span>  {t("WSTECZ")}
        </div>
        <div className={clsx('step-forward', (activeStep === data.list.length + 2) && 'disabled')} onClick={goNext}>
          {t("DALEJ")} <span className={'arrow-icon'}> &#62; </span>
        </div>

        <div className={clsx('step-title')}>
          {/* krok x z y ?? */}
          {data && data.list && data.list[activeStep - 2] && data.list[activeStep - 2].title}
          {/* {activeStep === data.list.length &&
          <div></div>
          } */}
          <div className='step-nr'>
            {activeStep + 1} / {data.list.length + 3}
          </div>
          {/* TODO: tu tez musi być prefeiniowany tytul dla statniego kroku  */}
        </div>

      </div>
      <div className={clsx(activeStep === 0 ? 'content-welcome-page': 'content')} id={"content"}>

        {data.list.map((set, setIndex) => {
          return <div key={'set' + setIndex} className={clsx('app-step', setIndex + 2 !== activeStep && 'hidden')}>
            <Grid container spacing={1} justifyContent={"center"} alignItems={"stretch"} >
              {/* <Grid item xs={12}> <div className={"title"}> {set.title} </div> </Grid> */}
              {set.items.map((item, index) => {
                let type = item.type || set.type
                if (type === "multi_choice") {
                  return <Grid key={'multi' + index} item xs={12} sm = {getColumnSmSize(set.items.length)} md={getColumnSize(set.items.length)}>
                    <MultiChoiceItem
                      data={item}
                      updateBasket={updateBasket}
                      basket={basket}
                    />
                  </Grid>
                }
                if (type === "numeric_choice") {
                  return <Grid key={'numeric' + index} item xs={12} sm = {getColumnSmSize(set.items.length)} md={getColumnSize(set.items.length)}>
                    <NumericChoiceItem
                      data={item}
                      updateBasket={updateBasket}
                      basket={basket}
                    />
                  </Grid>
                }
                if (type === "mixed_choice") {
                  return <Grid key={'mixed' + index} item xs={12} sm = {getColumnSmSize(set.items.length)}  md={getColumnSize(set.items.length)}>
                    <MixedChoiceItem
                      data={item}
                      updateBasket={updateBasket}
                      basket={basket}
                    />
                  </Grid>
                }

              })}

              {/* <Grid item xs={12} >
                <div className={"sum-label"}>{set.footer}:  {getSum(setIndex)}zł </div>
              </Grid> */}

            </Grid>
          </div>
        })}
        {activeStep === 0 && renderScreen()}
        {activeStep === 1 && renderStartScreen()}
        {data.list.length + 2 === activeStep && getSummary()}
      </div>
      <div className="footer">
        {activeStep !== 0 &&
            <div>
              {t("Obecna wartość zamówienia:")} {sum()} {t("zł")}

              {/*{ activeStep === data.list.length + 2 && <div className={clsx('test-btn', !formValid() && 'disabled')} onClick={sendTestData}>[Wyślij ofertę na maila]</div> }*/}
              {/*<div className='test-btn' onClick={clearBasket}>[wyczysc]</div>*/}
              {/* TODO: przyciski wyslij zamowienie, pobierz pdf, ... */}
            </div>
        }

      </div>
    </div>
  );
};

export default App;
