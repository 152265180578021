import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import clsx from 'clsx';
import { Checkbox, Input } from "@mui/material";
import md5 from 'md5';
import _ from 'lodash';

function MixedChoiceItem(props) {
    const { t, i18n } = useTranslation();

    const optionsLen = useState(props.data.options.length);
    const [selectedOptions, setSelectedOptions] = useState(Array(optionsLen));
    const [count, setCount] = useState(0);
    const [selected, setSelected] = useState(false);

    const _id = props.data.id ? props.data.id : md5(props.data.img + props.data.name);

    useEffect(() => {
        if (props.basket && props.basket[_id]) {
            const isSame = _.isEqual(props.basket[_id].value.selectedOption, selectedOptions);
            if (!isSame) {
                setSelectedOptions(props.basket[_id].value.selectedOption);
            }
            if (selected !== props.basket[_id].selected) {
                setSelected(props.basket[_id].selected);
            }
            if (count !== props.basket[_id].value.count) {
                setCount(props.basket[_id].value.count);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.basket]);

    const handleSelectItem = (value, index) => {
        let selectedTmp = [...selectedOptions];
        selectedTmp[index] = value;
        setSelectedOptions(selectedTmp);
        if (props.updateBasket && getSum(selectedTmp, count)) {
            setSelected(true);
            props.updateBasket(_id, {
                value: getSum(selectedTmp, count), name: props.data.name,
                count: count,
                selectedOption: selectedTmp
            }, true);
        }
    }

    const updateCount = (event) => {
        const nv = event.target.value;
        setCount(nv);
        if (props.updateBasket && getSum(selectedOptions, nv)) {
            setSelected(true);
            props.updateBasket(_id, {
                value: getSum(selectedOptions, nv), name: props.data.name,
                count: nv,
                selectedOption: selectedOptions
            }, true);
        } else {
            setSelected(false);
            props.updateBasket(_id, {
                value: getSum(selectedOptions, nv), name: props.data.name,
                count: nv,
                selectedOption: selectedOptions
            }, false);
        }
    }

    const getSum = (selectedOptions, count) => {
        let key = "";
        selectedOptions.forEach((opt, index) => {
            key += opt;
            if (index < selectedOptions.length - 1) key += ","
        });
        let selectedItem = props.data.values.find((val) => val.text === key);
        return selectedItem?.value * count || 0;
    }

    const handleChange = (event) => {
        setSelected(event.target.checked);
        props.updateBasket(_id, {
            value: getSum(selectedOptions, count), name: props.data.name,
            count: count,
            selectedOption: selectedOptions
        }, event.target.checked);
    };

    return (
        <div className='item '>
            <div className='item-header-image'>
                <img src={props.data.img} alt="no" />
            </div>
            <div className='item-title'>{props.data.name}</div>
            <div className='item-description'>{props.data.description}</div>

            {props.data.options.map((opt, index) => {
                return <div key={'msx' + index} className={clsx('item-option', index === props.data.options.length && "with-one")}>
                    <div className='option-question'>{opt.prompt}</div>
                    <div className='option-input option-values-3 values-4'>
                        {opt.values.map((val, si) => {
                            return <div key={'mss' + si} onClick={() => { handleSelectItem(val, index) }} className={clsx('option-value', selectedOptions[index] === val && "selected")}>{val} </div>
                        })}
                    </div>
                </div>
            })}

            <div className='item-numeric' >
                <div className='option-question'>{props.data.numericLabel}</div>
                <div className='option-input' style={{ fontSize: 10 }}>
                    {/* <Input type="number" value={count} onChange={(e)=>{setCount(e.target.value)}}/></div> */}
                    <Input type="number" value={count} onChange={updateCount} /></div>
            </div>

            <div className='item-value'>
                <div className='value-selected-item'>
                    <Checkbox style={{ padding: 0 }} checked={selected} onChange={handleChange} />
                </div>
                <div className={clsx('value-sum', !selected && 'not-selected')}>
                    {getSum(selectedOptions, count)} zł
                </div>
            </div>
        </div>
    );
}

export default MixedChoiceItem;
